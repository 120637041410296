import { Config } from './types';
export const baseApiUri = process.env.REACT_APP_API_URL || 'https://ajhh5uckgk.eu-west-1.awsapprunner.com/api/v1';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'Instacart',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://imagedelivery.net/YV7tiPr1pNu8eWcEhMhZuA/635b4eb6-7e49-4956-0104-0613fd917600/public',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#108910',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#fff',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '#000',
};
